import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Document, Page, pdfjs} from "react-pdf";
import {useLocation} from '@umijs/max';
import style from "./index.module.less";
import BaseTopNav from "@/components/base/baseTopNav";
import {BaseLoadingDisplay} from "@/components/base/baseLoadingDisplay";
import {Pagination} from "@nextui-org/react";
// 配置 worker（重要！否则可能无法渲染）
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export default () => {
    const {state = {} as any} = useLocation();
    const {url, title} = state || {};
    const {t} = useTranslation();
    const [pageTotalNumber, setPageTotalNumber] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState(1);
    const pdfUrl = url;

    const [loading, setLoading] = useState(true);

    // 添加 hooks
    const [scale, setScale] = useState(1.2); // 初始值建议 1.2
    const containerRef = useRef<HTMLDivElement>(null);

    // 计算合适缩放比例的函数
    const calculateScale = () => {
        if (!containerRef.current) return 1.2;

        // 获取容器有效宽度（示例按宽度适配）
        const containerWidth = containerRef.current.offsetWidth - 32; // 留出边距
        const pdfPageWidth = 612; // 标准 PDF 页面宽度（8.5英寸 * 72DPI）

        // 计算适合容器宽度的比例
        return Math.min(containerWidth / pdfPageWidth, 2.5); // 限制最大缩放
    };

    // 在 useEffect 中监听窗口变化
    useEffect(() => {
        const handleResize = () => {
            setScale(calculateScale());
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // 初始化计算

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="flex flex-col  fixed top-0 left-0 right-0 bottom-0 sm:overflow-y-auto">
            <div className="flex-shrink-0">
                <BaseTopNav title={title}/>
            </div>
            <div className="flex-1 ">
                <BaseLoadingDisplay loading={loading} list={loading ? [] : [1]}>
                </BaseLoadingDisplay>
                <Document
                    file={pdfUrl}
                    loading={false}
                    onLoadSuccess={(v) => {
                        setPageTotalNumber(v.numPages);
                        setLoading(false)
                    }}
                    onEnded={() => {
                        setLoading(false)
                    }}
                    onError={() => {
                        setLoading(false)
                    }}
                >
                    <Page
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        className={style["pdf-page"]}
                    />
                </Document>
            </div>
            <div className={`flex justify-center flex-shrink-0`}>
                <Pagination  isCompact showControls classNames={{cursor:"text-[#fff]"}} initialPage={pageNumber} total={pageTotalNumber} onChange={(page) => {
                   setPageNumber(page)
                }}/>
            </div>
        </div>
    );
};
